import { useNavigate, useParams } from "react-router-dom";
import { FloatingMenu } from "../floating_menu/FloatingMenu";
import styles from "./ViewPort.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { OrganizationAPI } from "../../services/apis/organizationAPI";
import { RoutePaths } from "../../navigation/constants";
import { ProjectAPI } from "../../services/apis/projectAPI";
import { Auth } from "aws-amplify";
import { UserAPI } from "../../services/apis/userAPI";
import { serviceAPI } from "../../services/apis/serviceAPI";

export const ViewPort = () => {
  const { orgName, projectName, serviceName } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    if (orgName && projectName && serviceName) {
      OrganizationAPI.getOrganizationByOrganizationName(orgName).then(
        (organizationResult: any) => {
          if (organizationResult) {
            const organizationId = organizationResult[0].OrganizationId;
            ProjectAPI.getOrganizationProjectData(
              projectName,
              organizationId
            ).then((projectResult: any) => {
              if (projectResult) {
                const projectId = projectResult[0].ProjectId;
                serviceAPI
                  .getServiceDataByServiceName(serviceName)
                  .then((serviceResult: any) => {
                    if (serviceResult) {
                      const serviceId = serviceResult[0].ServiceId;
                      Auth.currentAuthenticatedUser().then((user) => {
                        if (!user.attributes["custom:role"]) {
                          UserAPI.getUserAdminAPI(
                            organizationId,
                            user.attributes.sub
                          ).then((isOrgAdmin) => {
                            if (!isOrgAdmin) {
                              UserAPI.getAssignedUserData(
                                organizationId,
                                projectId,
                                serviceId,
                                user.attributes.sub
                              ).then((userResult: any) => {
                                const associatedRoles =
                                  userResult[0].AssociatedRoles;
                                if (associatedRoles === "ROLE") {
                                  navigate(RoutePaths.PAGE_NOT_FOUND);
                                }
                                setLoading(false);
                              });
                            } else {
                              setLoading(false);
                            }
                          });
                        }
                      });
                    } else {
                      navigate(RoutePaths.PAGE_NOT_FOUND);
                    }
                  });
              } else {
                navigate(RoutePaths.PAGE_NOT_FOUND);
              }
            });
          } else {
            // invalid organization
            navigate(RoutePaths.PAGE_NOT_FOUND);
          }
        }
      );
    } else {
      navigate(RoutePaths.PAGE_NOT_FOUND);
    }
  }, []);
  const frameSrc = `${process.env.REACT_APP_PowerBIURL}&filter=ProjectEntries/${projectName}`;
  return (
    <div className={styles["view-port"]}>
      {loading && <CircularProgress />}
      {!loading && (
        <>
          <iframe
            id="iframe"
            style={{ overflow: "hidden", border: "none" }}
            title="DataQI"
            src={frameSrc}
            scrolling="no"
            width="100%"
            height="100%"
            allowFullScreen
          ></iframe>
          <div>
            <FloatingMenu />
          </div>
        </>
      )}
    </div>
  );
};