/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getDimDocks30 = /* GraphQL */ `query GetDimDocks30($pk: String!, $sk: String!) {
  getDimDocks30(pk: $pk, sk: $sk) {
    AssociatedRoles
    AssociatedSectors
    AssociatedUsers
    CheckDataType
    CheckDescription
    CheckFileType
    CheckId
    CheckName
    CheckTemplateValue
    ClientDescription
    ClientId
    ClientName
    CreatedBy
    CreatedOn
    DIM360ImageMetadata
    DIM3DWebViewerMetadata
    DoesServiceHaveChecks
    HasNotificationBeenRead
    HasSupersededRoles
    IsCheckActive
    IsClientActive
    IsDeactivateByOA
    IsDeactivateByPA
    IsDeactivateBySA
    IsDeleted
    IsOrganizationActive
    IsPhaseActive
    IsProjectActive
    IsRegionActive
    IsRoleActive
    IsSectorActive
    IsServiceActive
    IsUserActive
    LastActiveOrganization
    LogoUrl
    ModifiedBy
    ModifiedOn
    NotificationId
    NotificationMessage
    OrganizationBranding
    OrganizationDescription
    OrganizationId
    OrganizationLogoUrl
    OrganizationName
    OrganizationUrl
    PhaseDescription
    PhaseId
    PhaseName
    PinnedOrganization
    ProjectDescription
    ProjectEndDate
    ProjectId
    ProjectName
    ProjectStartDate
    RegionDescription
    RegionId
    RegionLatitude
    RegionLongitude
    RegionName
    RoleDescription
    RoleId
    RoleName
    SectorDescription
    SectorId
    SectorName
    ServiceDescription
    ServiceFormTemplate
    ServiceId
    ServiceName
    ServiceTemplateValues
    ServiceType
    ServiceUrl
    UserId
    Username
    pk
    sk
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDimDocks30QueryVariables,
  APITypes.GetDimDocks30Query
>;
export const listDimDocks30s = /* GraphQL */ `query ListDimDocks30s(
  $filter: TableDimDocks30FilterInput
  $limit: Int
  $nextToken: String
) {
  listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      AssociatedRoles
      AssociatedSectors
      AssociatedUsers
      CheckDataType
      CheckDescription
      CheckFileType
      CheckId
      CheckName
      CheckTemplateValue
      ClientDescription
      ClientId
      ClientName
      CreatedBy
      CreatedOn
      DIM360ImageMetadata
      DIM3DWebViewerMetadata
      DoesServiceHaveChecks
      HasNotificationBeenRead
      HasSupersededRoles
      IsCheckActive
      IsClientActive
      IsDeactivateByOA
      IsDeactivateByPA
      IsDeactivateBySA
      IsDeleted
      IsOrganizationActive
      IsPhaseActive
      IsProjectActive
      IsRegionActive
      IsRoleActive
      IsSectorActive
      IsServiceActive
      IsUserActive
      LastActiveOrganization
      LogoUrl
      ModifiedBy
      ModifiedOn
      NotificationId
      NotificationMessage
      OrganizationBranding
      OrganizationDescription
      OrganizationId
      OrganizationLogoUrl
      OrganizationName
      OrganizationUrl
      PhaseDescription
      PhaseId
      PhaseName
      PinnedOrganization
      ProjectDescription
      ProjectEndDate
      ProjectId
      ProjectName
      ProjectStartDate
      RegionDescription
      RegionId
      RegionLatitude
      RegionLongitude
      RegionName
      RoleDescription
      RoleId
      RoleName
      SectorDescription
      SectorId
      SectorName
      ServiceDescription
      ServiceFormTemplate
      ServiceId
      ServiceName
      ServiceTemplateValues
      ServiceType
      ServiceUrl
      UserId
      Username
      pk
      sk
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDimDocks30sQueryVariables,
  APITypes.ListDimDocks30sQuery
>;
export const listDimDocks40s = /* GraphQL */ `query ListDimDocks40s(
  $filter: TableDimDocks40FilterInput
  $limit: Int
  $nextToken: String
) {
  listDimDocks40s(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      AssociatedRoles
      AssociatedSectors
      AssociatedUsers
      CheckDataType
      CheckDescription
      CheckFileType
      CheckId
      CheckName
      CheckTemplateValue
      ClientDescription
      ClientId
      ClientName
      CreatedBy
      CreatedOn
      DIM360ImageMetadata
      DIM3DWebViewerMetadata
      DoesServiceHaveChecks
      HasNotificationBeenRead
      HasSupersededRoles
      IsCheckActive
      IsClientActive
      IsDeactivateByOA
      IsDeactivateByPA
      IsDeactivateBySA
      IsDeleted
      IsOrganizationActive
      IsPhaseActive
      IsProjectActive
      IsRegionActive
      IsRoleActive
      IsSectorActive
      IsServiceActive
      IsUserActive
      LastActiveOrganization
      LogoUrl
      ModifiedBy
      ModifiedOn
      NotificationId
      NotificationMessage
      OrganizationBranding
      OrganizationDescription
      OrganizationId
      OrganizationLogoUrl
      OrganizationName
      OrganizationUrl
      PhaseDescription
      PhaseId
      PhaseName
      PinnedOrganization
      ProjectDescription
      ProjectEndDate
      ProjectId
      ProjectName
      ProjectStartDate
      RegionDescription
      RegionId
      RegionLatitude
      RegionLongitude
      RegionName
      RoleDescription
      RoleId
      RoleName
      SectorDescription
      SectorId
      SectorName
      ServiceDescription
      ServiceFormTemplate
      ServiceId
      ServiceName
      ServiceTemplateValues
      ServiceType
      ServiceUrl
      UserId
      Username
      pk
      sk
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDimDocks40sQueryVariables,
  APITypes.ListDimDocks40sQuery
>;
