import { API, graphqlOperation } from "aws-amplify";
import { handleResponse } from "../config/apiMiddleWare";
import { listDimDocks30s } from "../../graphql/queries";

export const UserAPI = {
  async getAssignedUserData(
    organizationId: string,
    projectId: string,
    serviceId: string,
    userId: string
  ) {
    const queryParams = {
      limit: 5000000,
      filter: {
        pk: {
          eq: `ORGANIZATION#${organizationId}`,
        },
        sk: {
          eq: `PROJECT#${projectId}SERVICE#${serviceId}USER#${userId}`,
        },
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.graphql(
          graphqlOperation(listDimDocks30s, queryParams)
        );
        resolve(handleResponse(result));
      } catch (error) {
        reject(error);
      }
    });
  },

  async getUserAdminAPI(organizationId: string, userId: string) {
    const queryVariables = {
      limit: 5000000,
      filter: {
        pk: {
          eq: `ORGANIZATION#${organizationId}`,
        },
        sk: {
          eq: `USER#${userId}`,
        },
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result: any = await API.graphql(
          graphqlOperation(listDimDocks30s, queryVariables)
        );
        const roleId = result.data.listDimDocks30s.items[0].RoleId;
        if (roleId) {
          this.getRoleAPI(roleId).then((res: any) => {
            if (
              res.data.listDimDocks30s.items[0].RoleName ===
              "Organization Admin"
            ) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  },

  async getRoleAPI(roleId: string) {
    const queryVariables = {
      limit: 5000000,
      filter: {
        pk: {
          eq: "ROLE",
        },
        sk: {
          eq: `ROLE#${roleId}`,
        },
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.graphql(
          graphqlOperation(listDimDocks30s, queryVariables)
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  },
};